<template>
  <div>
    <CalendarWidgetSettings v-if="widgetDetails.widgetCategoryID === 2" :widgetDetails="widgetDetails" :defaultCulture="defaultCulture" />
  </div>
</template>
<script>
export default {
  name: 'Settings',
  props: ['widgetDetails', 'defaultCulture'],
  components: {
    CalendarWidgetSettings: () => import('./CalendarWidgetSettings.vue'),
  },
}
</script>
